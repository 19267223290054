import React from 'react'
import './Products.css'

import hddpModal1 from '../img/HDDP/HDDP-Model-1.PNG'
import hddpImg1 from '../img/HDDP/HDDP-img-1.PNG'
import hddpModal2 from '../img/HDDP/HDDP-Model-2.PNG'
import hddpModal3 from '../img/HDDP/HDDP-Model-3.PNG'
import hddpModal4 from '../img/HDDP/HDDP-Model-4.PNG'
import hddpModal5 from '../img/HDDP/HDDP-Model-5.PNG'
import hddpModal6 from '../img/HDDP/HDDP-Model-6.PNG'
import hddpImg2 from '../img/HDDP/HDDP-img-2.PNG'
import hddpModal7 from '../img/HDDP/HDDP-Model-7.PNG'
import hddpModal8 from '../img/HDDP/HDDP-Model-8.PNG'
import hbpModal1 from '../img/HBP/HBP-Model-1.PNG'
import hbpImg1 from '../img/HBP/HBP-img-1.PNG'
import hbpModal2 from '../img/HBP/HBP-Model-2.PNG'
import hbpModal3 from '../img/HBP/HBP-Model-3.PNG'
import hddsapModal1 from '../img/HDDSAP/HDDSAP-model-1.PNG'
import hddsapImg1 from '../img/HDDSAP/HDDSAP-img-1.PNG'
import hddsapModal2 from '../img/HDDSAP/HDDSAP-model-2.PNG'
import hparmImg1 from '../img/HPARM/HPARM-img-1.PNG'
import mmtImg1 from '../img/MTM/MTM-img-1.png'
import ppmImg1 from '../img/PPM/PPM-img-1.PNG'

const cssForHr = {
    width: "80%"
}

const CssforCustomProduct = {
    marginBottom: "1rem !important"
}

function Products() {
  return (
    <section id="products">
    <div class="row">
        <hr style={cssForHr} id="HDDP-model-1" />
        <h1 class="text-center product-heading">Hydraulic Deep Draw Press</h1>
        <div class="col-lg-6 col-md-12 col-sm-12 product-customCol" style={CssforCustomProduct} data-aos="fade-up">
            <h2 id="model-1">Model 1</h2>
            <div class="product-img">
                <img alt="#" src={hddpModal1} class="modelSmallimg" />
            </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 product-customCol" data-aos="fade-up">
            <div class="product-img">
                <img alt="#" src={hddpImg1} />
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 product-customCol">
            <table class="table">
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Unit</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>All Cylinder Ton Capacity</td>
                        <td>90 Ton</td>
                    </tr>
                    <tr>
                        <td>Rating Working Pressure of Main Hydraulic Cylinder</td>
                        <td>180 Kg/cm2</td>
                    </tr>
                    <tr>
                        <td>Max. Working Pressure of All other Cylinder</td>
                        <td>200 Kg/cm2</td>
                    </tr>
                    <tr>
                        <td>Number of Cylinders</td>
                        <td>5 Nos</td>
                    </tr>
                    <tr>
                        <td>Power of Motor</td>
                        <td>15 Hp</td>
                    </tr>
                    <tr>
                        <td>Dimension of Working Table</td>
                        <td>25" X 25" mm2</td>
                    </tr>
                    <tr>
                        <td>Dimension of Machine (LRXFBXH)</td>
                        <td>978 x 635 x 2743 mm3</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 product-customCol">
            <table class="table">   
                <thead>
                    <tr>
                        <th>Model</th>
                        <th>Brand</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Motor</td>
                        <td>BBL/CROMPTON</td>
                    </tr>
                    <tr>
                        <td>Oil Pump</td>
                        <td>VELJAN DENISON</td>
                    </tr>
                    <tr>
                        <td>Solenoid Directional Valve</td>
                        <td>YUKEN INDIA</td>
                    </tr>
                    <tr>
                        <td>Other Valves</td>
                        <td>POLYHYDRON</td>
                    </tr>
                    <tr>
                        <td>Cartridge Valve System</td>
                        <td>POLYHYDRON</td>
                    </tr>
                    <tr>
                        <td>MCB</td>
                        <td>SIMMENS</td>
                    </tr>
                    <tr>
                        <td>Controller</td>
                        <td>SIMMENS</td>
                    </tr>
                    <tr>
                        <td>AC Contactor</td>
                        <td>SIMMENS</td>
                    </tr>
                    <tr>
                        <td>Seal Ring</td>
                        <td>INDIA</td>
                    </tr>
                    <tr>
                        <td>Time Relay</td>
                        <td>FELE</td>
                    </tr>
                    <tr>
                        <td>Light Certain</td>
                        <td>SCHNEIDER</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <hr style={cssForHr} id="HDDP-model-2" />
        <div class="col-lg-6 col-md-12 col-sm-12 product-customCol" style={CssforCustomProduct} data-aos="fade-up">
            <h2>Model 2</h2>
            <div class="product-img">
                <img alt="#" src={hddpModal2} class="modelSmallimg" />
            </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 product-customCol" data-aos="fade-up">
            <div class="product-img">
                <img alt="#" src={hddpImg1} />
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 product-customCol">
            <table class="table">
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Unit</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>All Cylinder Ton Capacity</td>
                        <td>115 Ton</td>
                    </tr>
                    <tr>
                        <td>Rating Working Pressure of Main Hydraulic Cylinder</td>
                        <td>180 Kg/cm2</td>
                    </tr>
                    <tr>
                        <td>Max. Working Pressure of All other Cylinder</td>
                        <td>200 Kg/cm2</td>
                    </tr>
                    <tr>
                        <td>Number of Cylinders</td>
                        <td>5 Nos</td>
                    </tr>
                    <tr>
                        <td>Power of Motor</td>
                        <td>15 Hp</td>
                    </tr>
                    <tr>
                        <td>Dimension of Working Table</td>
                        <td>28" X 30" mm2</td>
                    </tr>
                    <tr>
                        <td>Dimension of Machine (LRXFBXH)</td>
                        <td>1054 x 711 x 3048 mm3</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 product-customCol">
            <table class="table">   
                <thead>
                    <tr>
                        <th>Model</th>
                        <th>Brand</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Motor</td>
                        <td>BBL/CROMPTON</td>
                    </tr>
                    <tr>
                        <td>Oil Pump</td>
                        <td>VELJAN DENISON</td>
                    </tr>
                    <tr>
                        <td>Solenoid Directional Valve</td>
                        <td>YUKEN INDIA</td>
                    </tr>
                    <tr>
                        <td>Other Valves</td>
                        <td>POLYHYDRON</td>
                    </tr>
                    <tr>
                        <td>Cartridge Valve System</td>
                        <td>POLYHYDRON</td>
                    </tr>
                    <tr>
                        <td>MCB</td>
                        <td>SIMMENS</td>
                    </tr>
                    <tr>
                        <td>Controller</td>
                        <td>SIMMENS</td>
                    </tr>
                    <tr>
                        <td>AC Contactor</td>
                        <td>SIMMENS</td>
                    </tr>
                    <tr>
                        <td>Seal Ring</td>
                        <td>INDIA</td>
                    </tr>
                    <tr>
                        <td>Time Relay</td>
                        <td>FELE</td>
                    </tr>
                    <tr>
                        <td>Light Certain</td>
                        <td>SCHNEIDER</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <hr style={cssForHr} id="HDDP-model-3" />
        <div class="col-lg-6 col-md-12 col-sm-12 product-customCol" style={CssforCustomProduct} data-aos="fade-up">
            <h2>Model 3</h2>
            <div class="product-img">
                <img alt="#" src={hddpModal3} class="modelSmallimg" />
            </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 product-customCol" data-aos="fade-up">
            <div class="product-img">
                <img alt="#" src={hddpImg1} />
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 product-customCol">
            <table class="table">
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Unit</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>All Cylinder Ton Capacity</td>
                        <td>160 Ton</td>
                    </tr>
                    <tr>
                        <td>Rating Working Pressure of Main Hydraulic Cylinder</td>
                        <td>180 Kg/cm2</td>
                    </tr>
                    <tr>
                        <td>Max. Working Pressure of All other Cylinder</td>
                        <td>200 Kg/cm2</td>
                    </tr>
                    <tr>
                        <td>Number of Cylinders</td>
                        <td>5 Nos</td>
                    </tr>
                    <tr>
                        <td>Power of Motor</td>
                        <td>15 Hp</td>
                    </tr>
                    <tr>
                        <td>Dimension of Working Table</td>
                        <td>32" X 34" mm2</td>
                    </tr>
                    <tr>
                        <td>Dimension of Machine (LRXFBXH)</td>
                        <td>1168 x 812.8 x 3480 mm3</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 product-customCol">
            <table class="table">   
                <thead>
                    <tr>
                        <th>Model</th>
                        <th>Brand</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Motor</td>
                        <td>BBL/CROMPTON</td>
                    </tr>
                    <tr>
                        <td>Oil Pump</td>
                        <td>VELJAN DENISON</td>
                    </tr>
                    <tr>
                        <td>Solenoid Directional Valve</td>
                        <td>YUKEN INDIA</td>
                    </tr>
                    <tr>
                        <td>Other Valves</td>
                        <td>POLYHYDRON</td>
                    </tr>
                    <tr>
                        <td>Cartridge Valve System</td>
                        <td>POLYHYDRON</td>
                    </tr>
                    <tr>
                        <td>MCB</td>
                        <td>SIMMENS</td>
                    </tr>
                    <tr>
                        <td>Controller</td>
                        <td>SIMMENS</td>
                    </tr>
                    <tr>
                        <td>AC Contactor</td>
                        <td>SIMMENS</td>
                    </tr>
                    <tr>
                        <td>Seal Ring</td>
                        <td>INDIA</td>
                    </tr>
                    <tr>
                        <td>Time Relay</td>
                        <td>FELE</td>
                    </tr>
                    <tr>
                        <td>Light Certain</td>
                        <td>SCHNEIDER</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <hr style={cssForHr} id="HDDP-model-4" />
        <div class="col-lg-6 col-md-12 col-sm-12 product-customCol" style={CssforCustomProduct} data-aos="fade-up">
            <h2>Model 4</h2>
            <div class="product-img">
                <img alt="#" src={hddpModal4} class="modelSmallimg" />
            </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 product-customCol" data-aos="fade-up">
            <div class="product-img">
                <img alt="#" src={hddpImg1} />
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 product-customCol">
            <table class="table">
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Unit</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>All Cylinder Ton Capacity</td>
                        <td>220 Ton</td>
                    </tr>
                    <tr>
                        <td>Rating Working Pressure of Main Hydraulic Cylinder</td>
                        <td>180 Kg/cm2</td>
                    </tr>
                    <tr>
                        <td>Max. Working Pressure of All other Cylinder</td>
                        <td>200 Kg/cm2</td>
                    </tr>
                    <tr>
                        <td>Number of Cylinders</td>
                        <td>5 Nos</td>
                    </tr>
                    <tr>
                        <td>Power of Motor</td>
                        <td>20 Hp</td>
                    </tr>
                    <tr>
                        <td>Dimension of Working Table</td>
                        <td>40" X 42" mm2</td>
                    </tr>
                    <tr>
                        <td>Dimension of Machine (LRXFBXH)</td>
                        <td>1321 x 1016 x 3988 mm3</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 product-customCol">
            <table class="table">   
                <thead>
                    <tr>
                        <th>Model</th>
                        <th>Brand</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Motor</td>
                        <td>BBL/CROMPTON</td>
                    </tr>
                    <tr>
                        <td>Oil Pump</td>
                        <td>VELJAN DENISON</td>
                    </tr>
                    <tr>
                        <td>Solenoid Directional Valve</td>
                        <td>YUKEN INDIA</td>
                    </tr>
                    <tr>
                        <td>Other Valves</td>
                        <td>POLYHYDRON</td>
                    </tr>
                    <tr>
                        <td>Cartridge Valve System</td>
                        <td>POLYHYDRON</td>
                    </tr>
                    <tr>
                        <td>MCB</td>
                        <td>SIMMENS</td>
                    </tr>
                    <tr>
                        <td>Controller</td>
                        <td>SIMMENS</td>
                    </tr>
                    <tr>
                        <td>AC Contactor</td>
                        <td>SIMMENS</td>
                    </tr>
                    <tr>
                        <td>Seal Ring</td>
                        <td>INDIA</td>
                    </tr>
                    <tr>
                        <td>Time Relay</td>
                        <td>FELE</td>
                    </tr>
                    <tr>
                        <td>Light Certain</td>
                        <td>SCHNEIDER</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <hr style={cssForHr} id="HDDP-model-5" />
        <div class="col-lg-6 col-md-12 col-sm-12 product-customCol" style={CssforCustomProduct} data-aos="fade-up">
            <h2>Model 5</h2>
            <div class="product-img">
                <img alt="#" src={hddpModal5} class="modelSmallimg" />
            </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 product-customCol" data-aos="fade-up">
            <div class="product-img">
                <img alt="#" src={hddpImg1} />
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 product-customCol">
            <table class="table">
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Unit</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>All Cylinder Ton Capacity</td>
                        <td>220 Ton</td>
                    </tr>
                    <tr>
                        <td>Rating Working Pressure of Main Hydraulic Cylinder</td>
                        <td>180 Kg/cm2</td>
                    </tr>
                    <tr>
                        <td>Max. Working Pressure of All other Cylinder</td>
                        <td>200 Kg/cm2</td>
                    </tr>
                    <tr>
                        <td>Number of Cylinders</td>
                        <td>5 Nos</td>
                    </tr>
                    <tr>
                        <td>Power of Motor</td>
                        <td>25 Hp</td>
                    </tr>
                    <tr>
                        <td>Dimension of Working Table</td>
                        <td>46" X 46" mm2</td>
                    </tr>
                    <tr>
                        <td>Dimension of Machine (LRXFBXH)</td>
                        <td>1550 x 1168 x 4090 mm3</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 product-customCol">
            <table class="table">   
                <thead>
                    <tr>
                        <th>Model</th>
                        <th>Brand</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Motor</td>
                        <td>BBL/CROMPTON</td>
                    </tr>
                    <tr>
                        <td>Oil Pump</td>
                        <td>VELJAN DENISON</td>
                    </tr>
                    <tr>
                        <td>Solenoid Directional Valve</td>
                        <td>YUKEN INDIA</td>
                    </tr>
                    <tr>
                        <td>Other Valves</td>
                        <td>POLYHYDRON</td>
                    </tr>
                    <tr>
                        <td>Cartridge Valve System</td>
                        <td>POLYHYDRON</td>
                    </tr>
                    <tr>
                        <td>MCB</td>
                        <td>SIMMENS</td>
                    </tr>
                    <tr>
                        <td>Controller</td>
                        <td>SIMMENS</td>
                    </tr>
                    <tr>
                        <td>AC Contactor</td>
                        <td>SIMMENS</td>
                    </tr>
                    <tr>
                        <td>Seal Ring</td>
                        <td>INDIA</td>
                    </tr>
                    <tr>
                        <td>Time Relay</td>
                        <td>FELE</td>
                    </tr>
                    <tr>
                        <td>Light Certain</td>
                        <td>SCHNEIDER</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <hr style={cssForHr} id="HDDP-model-6" />
        <div class="col-lg-6 col-md-12 col-sm-12 product-customCol" style={CssforCustomProduct} data-aos="fade-up">
            <h2>Model 6</h2>
            <div class="product-img">
                <img alt="#" src={hddpModal6} class="modelSmallimg" />
            </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 product-customCol" data-aos="fade-up">
            <div class="product-img">
                <img alt="#" src={hddpImg2} />
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 product-customCol">
            <table class="table">
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Unit</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>All Cylinder Ton Capacity</td>
                        <td>280 Ton</td>
                    </tr>
                    <tr>
                        <td>Rating Working Pressure of Main Hydraulic Cylinder</td>
                        <td>180 Kg/cm2</td>
                    </tr>
                    <tr>
                        <td>Max. Working Pressure of All other Cylinder</td>
                        <td>200 Kg/cm2</td>
                    </tr>
                    <tr>
                        <td>Number of Cylinders</td>
                        <td>7 Nos</td>
                    </tr>
                    <tr>
                        <td>Power of Motor</td>
                        <td>30 Hp</td>
                    </tr>
                    <tr>
                        <td>Dimension of Working Table</td>
                        <td>56" X 58" mm2</td>
                    </tr>
                    <tr>
                        <td>Dimension of Machine (LRXFBXH)</td>
                        <td>1880 x 1422 x 4521 mm3</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 product-customCol">
            <table class="table">   
                <thead>
                    <tr>
                        <th>Model</th>
                        <th>Brand</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Motor</td>
                        <td>BBL/CROMPTON</td>
                    </tr>
                    <tr>
                        <td>Oil Pump</td>
                        <td>VELJAN DENISON</td>
                    </tr>
                    <tr>
                        <td>Solenoid Directional Valve</td>
                        <td>YUKEN INDIA</td>
                    </tr>
                    <tr>
                        <td>Other Valves</td>
                        <td>POLYHYDRON</td>
                    </tr>
                    <tr>
                        <td>Cartridge Valve System</td>
                        <td>POLYHYDRON</td>
                    </tr>
                    <tr>
                        <td>MCB</td>
                        <td>SIMMENS</td>
                    </tr>
                    <tr>
                        <td>Controller</td>
                        <td>SIMMENS</td>
                    </tr>
                    <tr>
                        <td>AC Contactor</td>
                        <td>SIMMENS</td>
                    </tr>
                    <tr>
                        <td>Seal Ring</td>
                        <td>INDIA</td>
                    </tr>
                    <tr>
                        <td>Time Relay</td>
                        <td>FELE</td>
                    </tr>
                    <tr>
                        <td>Light Certain</td>
                        <td>SCHNEIDER</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <hr style={cssForHr} id="HDDP-model-7" />
        <div class="col-lg-6 col-md-12 col-sm-12 product-customCol" style={CssforCustomProduct} data-aos="fade-up">
            <h2>Model 7</h2>
            <div class="product-img">
                <img alt="#" src={hddpModal7} class="modelSmallimg" />
            </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 product-customCol" data-aos="fade-up">
            <div class="product-img">
                <img alt="#" src={hddpImg2} />
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 product-customCol">
            <table class="table">
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Unit</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>All Cylinder Ton Capacity</td>
                        <td>340 Ton</td>
                    </tr>
                    <tr>
                        <td>Rating Working Pressure of Main Hydraulic Cylinder</td>
                        <td>180 Kg/cm2</td>
                    </tr>
                    <tr>
                        <td>Max. Working Pressure of All other Cylinder</td>
                        <td>200 Kg/cm2</td>
                    </tr>
                    <tr>
                        <td>Number of Cylinders</td>
                        <td>7 Nos</td>
                    </tr>
                    <tr>
                        <td>Power of Motor</td>
                        <td>30 Hp</td>
                    </tr>
                    <tr>
                        <td>Dimension of Working Table</td>
                        <td>64" X 66" mm2</td>
                    </tr>
                    <tr>
                        <td>Dimension of Machine (LRXFBXH)</td>
                        <td>2108 x 165 x 4522 mm3</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 product-customCol">
            <table class="table">   
                <thead>
                    <tr>
                        <th>Model</th>
                        <th>Brand</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Motor</td>
                        <td>BBL/CROMPTON</td>
                    </tr>
                    <tr>
                        <td>Oil Pump</td>
                        <td>VELJAN DENISON</td>
                    </tr>
                    <tr>
                        <td>Solenoid Directional Valve</td>
                        <td>YUKEN INDIA</td>
                    </tr>
                    <tr>
                        <td>Other Valves</td>
                        <td>POLYHYDRON</td>
                    </tr>
                    <tr>
                        <td>Cartridge Valve System</td>
                        <td>POLYHYDRON</td>
                    </tr>
                    <tr>
                        <td>MCB</td>
                        <td>SIMMENS</td>
                    </tr>
                    <tr>
                        <td>Controller</td>
                        <td>SIMMENS</td>
                    </tr>
                    <tr>
                        <td>AC Contactor</td>
                        <td>SIMMENS</td>
                    </tr>
                    <tr>
                        <td>Seal Ring</td>
                        <td>INDIA</td>
                    </tr>
                    <tr>
                        <td>Time Relay</td>
                        <td>FELE</td>
                    </tr>
                    <tr>
                        <td>Light Certain</td>
                        <td>SCHNEIDER</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <hr style={cssForHr} id="HDDP-model-8" />
        <div class="col-lg-6 col-md-12 col-sm-12 product-customCol" style={CssforCustomProduct} data-aos="fade-up">
            <h2>Model 8</h2>
            <div class="product-img">
                <img alt="#" src={hddpModal8} class="modelSmallimg" />
            </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 product-customCol" data-aos="fade-up">
            <div class="product-img">
                <img alt="#" src={hddpImg2} />
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 product-customCol">
            <table class="table">
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Unit</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Rating Working Pressure of Main Hydraulic Cylinder</td>
                        <td>180 Kg/cm2</td>
                    </tr>
                    <tr>
                        <td>Max. Working Pressure of All other Cylinder</td>
                        <td>200 Kg/cm2</td>
                    </tr>
                    <tr>
                        <td>Number of Cylinders</td>
                        <td>7 Nos</td>
                    </tr>
                    <tr>
                        <td>Power of Motor</td>
                        <td>30 Hp</td>
                    </tr>
                    <tr>
                        <td>Dimension of Working Table</td>
                        <td>76"X 78" mm2</td>
                    </tr>
                    <tr>
                        <td>Circle Size</td>
                        <td>60" X 72"</td>
                    </tr>
                    <tr>
                        <td>Dimension of Machine (LRXFBXH)</td>
                        <td>978 X 635 x 2745 mm3</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 product-customCol">
            <table class="table">   
                <thead>
                    <tr>
                        <th>Model</th>
                        <th>Brand</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Motor</td>
                        <td>BBL/CROMPTON</td>
                    </tr>
                    <tr>
                        <td>Oil Pump</td>
                        <td>VELJAN DENISON</td>
                    </tr>
                    <tr>
                        <td>Solenoid Directional Valve</td>
                        <td>YUKEN INDIA</td>
                    </tr>
                    <tr>
                        <td>Other Valves</td>
                        <td>POLYHYDRON</td>
                    </tr>
                    <tr>
                        <td>Cartridge Valve System</td>
                        <td>POLYHYDRON</td>
                    </tr>
                    <tr>
                        <td>MCB</td>
                        <td>SIMMENS</td>
                    </tr>
                    <tr>
                        <td>Controller</td>
                        <td>SIMMENS</td>
                    </tr>
                    <tr>
                        <td>AC Contactor</td>
                        <td>SIMMENS</td>
                    </tr>
                    <tr>
                        <td>Seal Ring</td>
                        <td>INDIA</td>
                    </tr>
                    <tr>
                        <td>Time Relay</td>
                        <td>FELE</td>
                    </tr>
                    <tr>
                        <td>Light Certain</td>
                        <td>SCHNEIDER</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <hr style={cssForHr} id="HBP-model-1" />
        <h1 class="text-center product-heading">Hydraulic Bailling Press</h1>
        <div class="col-lg-6 col-md-12 col-sm-12 product-customCol" style={CssforCustomProduct} data-aos="fade-up">
            <h2>Model 1</h2>
            <div class="product-img">
                <img alt="#" src={hbpModal1} class="modelSmallimg" />
            </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 product-customCol" data-aos="fade-up">
            <div class="product-img">
                <img alt="#" src={hbpImg1} />
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 product-customCol">
            <table class="table">
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Unit</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Number of Cylinders</td>
                        <td>4 Nos</td>
                    </tr>
                    <tr>
                        <td>Power of Motor</td>
                        <td>20 Hp</td>
                    </tr>
                    <tr>
                        <td>Bail Size</td>
                        <td>15" X 15"</td>
                    </tr>
                    <tr>
                        <td>Size Linear</td>
                        <td>10 mm</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 product-customCol">
            <table class="table">   
                <thead>
                    <tr>
                        <th>Model</th>
                        <th>Brand</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Motor</td>
                        <td>BBL/CROMPTON</td>
                    </tr>
                    <tr>
                        <td>Hydraulic Pump</td>
                        <td>VELJAN DENISON / YUKEN INDIA</td>
                    </tr>
                    <tr>
                        <td>Solenoid Directional Valve</td>
                        <td>YUKEN INDIA</td>
                    </tr>
                    <tr>
                        <td>Other Valves</td>
                        <td>POLYHYDRON</td>
                    </tr>
                    <tr>
                        <td>Cartridge Valve System</td>
                        <td>POLYHYDRON</td>
                    </tr>
                    <tr>
                        <td>Controller</td>
                        <td>SIMMENS</td>
                    </tr>
                    <tr>
                        <td>Seal Ring</td>
                        <td>MADE IN INDIA</td>
                    </tr>
                    <tr>
                        <td>Oil Cooler</td>
                        <td>MADE IN INDIA</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <hr style={cssForHr} id="HBP-model-2" />
        <div class="col-lg-6 col-md-12 col-sm-12 product-customCol" style={CssforCustomProduct} data-aos="fade-up">
            <h2>Model 2</h2>
            <div class="product-img">
                <img alt="#" src={hbpModal2} class="modelSmallimg" />
            </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 product-customCol" data-aos="fade-up">
            <div class="product-img">
                <img alt="#" src={hbpImg1} />
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 product-customCol">
            <table class="table">
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Unit</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Number of Cylinders</td>
                        <td>4 Nos</td>
                    </tr>
                    <tr>
                        <td>Power of Motor</td>
                        <td>20 Hp</td>
                    </tr>
                    <tr>
                        <td>Bail Size</td>
                        <td>18" X 18"</td>
                    </tr>
                    <tr>
                        <td>Size Linear</td>
                        <td>10 mm</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 product-customCol">
            <table class="table">   
                <thead>
                    <tr>
                        <th>Model</th>
                        <th>Brand</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Motor</td>
                        <td>BBL/CROMPTON</td>
                    </tr>
                    <tr>
                        <td>Hydraulic Pump</td>
                        <td>VELJAN DENISON / YUKEN INDIA</td>
                    </tr>
                    <tr>
                        <td>Solenoid Directional Valve</td>
                        <td>YUKEN INDIA</td>
                    </tr>
                    <tr>
                        <td>Other Valves</td>
                        <td>POLYHYDRON</td>
                    </tr>
                    <tr>
                        <td>Cartridge Valve System</td>
                        <td>POLYHYDRON</td>
                    </tr>
                    <tr>
                        <td>Controller</td>
                        <td>SIMMENS</td>
                    </tr>
                    <tr>
                        <td>Seal Ring</td>
                        <td>MADE IN INDIA</td>
                    </tr>
                    <tr>
                        <td>Oil Cooler</td>
                        <td>MADE IN INDIA</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <hr style={cssForHr} id="HBP-model-3" />
        <div class="col-lg-6 col-md-12 col-sm-12 product-customCol" style={CssforCustomProduct} data-aos="fade-up">
            <h2>Model 3</h2>
            <div class="product-img">
                <img alt="#" src={hbpModal3} class="modelSmallimg" />
            </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 product-customCol" data-aos="fade-up">
            <div class="product-img">
                <img alt="#" src={hbpImg1} />
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 product-customCol">
            <table class="table">
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Unit</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Number of Cylinders</td>
                        <td>4 Nos</td>
                    </tr>
                    <tr>
                        <td>Power of Motor</td>
                        <td>20 Hp</td>
                    </tr>
                    <tr>
                        <td>Bail Size</td>
                        <td>20" X 20"</td>
                    </tr>
                    <tr>
                        <td>Size Linear</td>
                        <td>10 mm</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 product-customCol">
            <table class="table">   
                <thead>
                    <tr>
                        <th>Model</th>
                        <th>Brand</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Motor</td>
                        <td>BBL/CROMPTON</td>
                    </tr>
                    <tr>
                        <td>Hydraulic Pump</td>
                        <td>VELJAN DENISON / YUKEN INDIA</td>
                    </tr>
                    <tr>
                        <td>Solenoid Directional Valve</td>
                        <td>YUKEN INDIA</td>
                    </tr>
                    <tr>
                        <td>Other Valves</td>
                        <td>POLYHYDRON</td>
                    </tr>
                    <tr>
                        <td>Cartridge Valve System</td>
                        <td>POLYHYDRON</td>
                    </tr>
                    <tr>
                        <td>Controller</td>
                        <td>SIMMENS</td>
                    </tr>
                    <tr>
                        <td>Seal Ring</td>
                        <td>MADE IN INDIA</td>
                    </tr>
                    <tr>
                        <td>Oil Cooler</td>
                        <td>MADE IN INDIA</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <hr style={cssForHr} id="HDDSAP-model-1" />
        <h1 class="text-center product-heading">Hydraulic Deep Draw Press Single Action</h1>
        <div class="col-lg-6 col-md-12 col-sm-12 product-customCol" style={CssforCustomProduct} data-aos="fade-up">
            <h2>Model 1</h2>
            <div class="product-img">
                <img alt="#" src={hddsapModal1} class="modelSmallimg" />
            </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 product-customCol" data-aos="fade-up">
            <div class="product-img">
                <img alt="#" src={hddsapImg1} />
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 product-customCol">
            <table class="table">
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Unit</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Number of Cylinders</td>
                        <td>2 Nos</td>
                    </tr>
                    <tr>
                        <td>Power of Motor</td>
                        <td>15 Hp</td>
                    </tr>
                    <tr>
                        <td>Dimension of Working Table</td>
                        <td>24" X 24"</td>
                    </tr>
                    <tr>
                        <td>Circle Size</td>
                        <td>18"</td>
                    </tr>
                    <tr>
                        <td>Oil Tank Capacity</td>
                        <td>400 Ltr</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 product-customCol">
            <table class="table">   
                <thead>
                    <tr>
                        <th>Model</th>
                        <th>Brand</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Motor</td>
                        <td>BBL/CROMPTON</td>
                    </tr>
                    <tr>
                        <td>Hydraulic Pump</td>
                        <td>VELJAN DENISON</td>
                    </tr>
                    <tr>
                        <td>Solenoid Directional Valve</td>
                        <td>YUKEN INDIA</td>
                    </tr>
                    <tr>
                        <td>Other Valves</td>
                        <td>POLYHYDRON</td>
                    </tr>
                    <tr>
                        <td>Cartridge Valve System</td>
                        <td>POLYHYDRON</td>
                    </tr>
                    <tr>
                        <td>Controller</td>
                        <td>SIMMENS</td>
                    </tr>
                    <tr>
                        <td>Seal Ring</td>
                        <td>INDIA</td>
                    </tr>
                    <tr>
                        <td>Oil Cooler</td>
                        <td>MADE IN INDIA</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <hr style={cssForHr} id="HDDSAP-model-2" />
        <div class="col-lg-6 col-md-12 col-sm-12 product-customCol" style={CssforCustomProduct} data-aos="fade-up">
            <h2>Model 2</h2>
            <div class="product-img">
                <img alt="#" src={hddsapModal2} class="modelSmallimg" />
            </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 product-customCol" data-aos="fade-up">
            <div class="product-img">
                <img alt="#" src={hddsapImg1} />
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 product-customCol">
            <table class="table">
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Unit</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Number of Cylinders</td>
                        <td>2 Nos</td>
                    </tr>
                    <tr>
                        <td>Power of Motor</td>
                        <td>15 Hp</td>
                    </tr>
                    <tr>
                        <td>Dimension of Working Table</td>
                        <td>24" X 24"</td>
                    </tr>
                    <tr>
                        <td>Circle Size</td>
                        <td>18"</td>
                    </tr>
                    <tr>
                        <td>Oil Tank Capacity</td>
                        <td>400 Ltr</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 product-customCol">
            <table class="table">   
                <thead>
                    <tr>
                        <th>Model</th>
                        <th>Brand</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Motor</td>
                        <td>BBL/CROMPTON</td>
                    </tr>
                    <tr>
                        <td>Hydraulic Pump</td>
                        <td>VELJAN DENISON</td>
                    </tr>
                    <tr>
                        <td>Solenoid Directional Valve</td>
                        <td>YUKEN INDIA</td>
                    </tr>
                    <tr>
                        <td>Other Valves</td>
                        <td>POLYHYDRON</td>
                    </tr>
                    <tr>
                        <td>Cartridge Valve System</td>
                        <td>POLYHYDRON</td>
                    </tr>
                    <tr>
                        <td>Controller</td>
                        <td>SIMMENS</td>
                    </tr>
                    <tr>
                        <td>Seal Ring</td>
                        <td>INDIA</td>
                    </tr>
                    <tr>
                        <td>Oil Cooler</td>
                        <td>MADE IN INDIA</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <hr style={cssForHr} id="HPARM-model-1" />
        <h1 class="text-center product-heading">Hydraulic Punching and Riveting Machine</h1>
        <div class="col-lg-12 col-md-12 col-sm-12 product-customCol" data-aos="fade-up">
            <div class="product-img">
                <img alt="#" src={hparmImg1} />
            </div>
        </div>
        <div class="col-lg-12 col-md-12 product-customCol">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 customProduct">
                    <h3>Hydraulic Punching and Riveting Machine (MIPR):</h3>
                    <p>For Punching and Reveting</p>
                    <p>Mc Fabricated Body</p>
                    <p>1 Function For Punching</p>
                    <p>2 Function For Riveting</p>
                    <p>Power of Motor : 5 Hp</p>
                    <p>Oil Capacity : 100 Ltr</p>
                    <p>Number of Cylinder : 2</p>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <table class="table">   
                        <thead>
                            <tr>
                                <th>Model</th>
                                <th>Brand</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Motor</td>
                                <td>BBL/CROMPTON</td>
                            </tr>
                            <tr>
                                <td>Solenoid Valve</td>
                                <td>YUKEN INDIA</td>
                            </tr>
                            <tr>
                                <td>Hydraulic Pump</td>
                                <td>YOKEN INDIA</td>
                            </tr>
                            <tr>
                                <td>Cartridge Valve System</td>
                                <td>POLYHYDRON</td>
                            </tr>
                            <tr>
                                <td>Controller</td>
                                <td>SIMMENS</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <hr style={cssForHr} id="MTM-model-1" />
        <h1 class="text-center product-heading">Mechanical Trimming Machine</h1>
        <div class="col-lg-6 col-md-12 col-sm-12 product-customCol forSmallDisplay" data-aos="fade-up">
            <div class="product-img">
                <img alt="#" src={mmtImg1} />
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 product-customCol" data-aos="fade-up">
                <div class="customProduct">
                    <h3>Mechanical Trimming Machine (MITM):</h3>
                    <p>For Cutting the Edge of Tope</p>
                    <p>MS Fabricated Body</p>
                    <p>WPS Cutting Cutter</p>
                    <p>Material Type: Aluminium</p>
                    <p>Power of Motor : 1 Hp</p>
                    <p>Motor Type: Flange Type</p>
                    <p>Operating Type: Manual</p>
                    <p>Connection: 3 Phase</p>
                    <p>Pulley: Made in India</p>
                    <p>Belt: Made in India</p>
                </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 product-customCol forLargeDisplay" data-aos="fade-up">
            <div class="product-img">
                <img alt="#" src={mmtImg1} />
            </div>
        </div>
        <hr style={cssForHr} id="PPM-model-1" />
        <h1 class="text-center product-heading">Pneumatic Polishing Machine</h1>
        <div class="col-lg-6 col-md-12 col-sm-12 product-customCol forSmallDisplay" data-aos="fade-up">
            <div class="product-img">
                <img alt="#" src={ppmImg1} />
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 product-customCol" data-aos="fade-up">
                <div class="customProduct">
                    <h3>Pneumatic Polishing Machine (MIPM):</h3>
                    <p>For Polishing the Tope</p>
                    <p>MS Fabricated Body</p>
                    <p>Graded Casting Head</p>  
                    <p>Material Type: Aluminium</p>
                    <p>Polish Capacity: 10" To 28"</p>
                    <p>Vaccum Compressor: 1 Hp</p>
                    <p>Power of Motor : 3 Hp</p>
                    <p>Motor Type: Flange Type</p>
                    <p>Operating Type: Manual</p>
                    <p>Connection: 3 Phase</p>
                </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 product-customCol forLargeDisplay" data-aos="fade-up">
            <div class="product-img">
                <img alt="#" src={ppmImg1} />
            </div>
        </div>
    </div>
</section>
  )
}

export default Products
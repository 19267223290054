import React from "react";
import carouselImg1 from "../img/carousel-img-1.jpg";
import carouselImg2 from "../img/carousel-img-2.JPG";
import aboutImg1 from "../img/about-img-1.png";
import aboutImg2 from "../img/about-img-2.png";
import aboutImg3 from "../img/about-img-3.png";
import aboutImg4 from "../img/about-img-4.jpg";
import carouselImg1ForSmallWidth from '../img/carousel-img-1-forSmallwidth.jpg'
import carouselImg2ForSmallWidth from '../img/carousel-img-2-forSmallwidth.JPG'


const cssForHr = {
  width: "80%",
};

function Home() {
  // useEffect(() => {
  //   AOS.init({
  //     offset: 350,
  //     duration: 600,
  //   });
  // }, []);

  return (
    <div>
      <section id="home">
        <div className="carousel-background-setup">
          <div
            id="carouselExampleCaptions"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-indicators">
              <button
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide-to="0"
                className="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
            </div>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src={carouselImg1}
                  className="d-block w-100 carousel-image"
                  alt="..."
                />
                <img
                  src={carouselImg1ForSmallWidth}
                  className="d-block w-100 carousel-image"
                  alt="..."
                />
                <div className="carousel-caption">
                  <h5
                    className="animated bounceInRight"
                    style={{ animationDelay: "1s" }}
                  >
                    About Hydraulic Press
                  </h5>
                  <p
                    className="animated bounceInLeft d-md-block"
                    style={{ animationDelay: "1s" }}
                  >
                    A hydraulic press is a machine press using a hydraulic
                    cylinder to generate a compressive force. It uses the
                    hydraulic equivalent of a mechanical lever, and was also
                    known as a Bramah press after the inventor, Joseph Bramah,
                    of England. He invented and was issued a patent on this
                    press in 1795
                  </p>
                </div>
              </div>
              <div className="carousel-item">
                <img src={carouselImg2} className="d-block w-100 carousel-image " />
                <img
                  src={carouselImg2ForSmallWidth}
                  className="d-block w-100 carousel-image "
                />
                <div className="carousel-caption">
                  <h5 className="animatedDown">Uses of Hydraulic Press</h5>
                  <p className="animatedUp">
                    Hydraulic presses are commonly used for forging, clinching,
                    moulding, blanking, punching, deep drawing, and metal
                    forming operations. Hydraulic presses are also used for
                    stretch forming, rubber pad forming, and powder compacting.
                    The hydraulic press is advantageous in manufacturing, it
                    gives the ability to create more intricate shapes and can be
                    economical with materials
                  </p>
                </div>
              </div>
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </section>
      <section id="about">
        <div className="about-header">
          <h1 className="text-center about-heading">Visit Our Company</h1>
          <h2 className="text-center">WELCOME TO OUR COMPANY MACBON INDUSTRIES</h2>
        </div>
        <div className="about-container">
          <div
            className="row row-alignment align-items-center justify-content-center"
            data-aos="fade-up"
          >
            <div className="col-sm-12">
              <h2 className="about-heading">Macbon Industries</h2>
              <p className="about-para">
                Established in the year 1999, at Ahmedabad (Gujarat, India), we,
                “Macbon Industries”, are instrumental in manufacturing a fine
                quality assortment of Draw Press Machines, Rolling Machine, etc.
                The range made available by us is manufactured using excellent
                quality wrought iron, steel, brass and other metals that are
                sourced from most trustworthy & reliable vendors of market with
                the help of our procuring agents. These products are widely
                acknowledged in market owing to their intrusive attributes such
                as dimensional accuracy, fine finishing, high tensile strength,
                excellent polish, corrosion & abrasion resistance and easy
                installation. Under the headship of our mentor “Mr. Sanju
                Malvi", we have been able to accomplish emerging requirements
                and demands of our customers.
              </p>
            </div>
          </div>
          <hr stylel={cssForHr} />
          <div
            className="row row-alignment align-items-center justify-content-center"
            data-aos="fade-up"
          >
            <div className="col-lg-6 col-md-6 forLargeDisplay">
              <div className="col-setup">
                <h3>Why Us</h3>
                <p>Low Maintenance</p>
                <p>Good Ethics in Business</p>
                <p>Quality Indian Craftsmanship in every machine we build</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <img src={aboutImg1} className="col-img" />
            </div>
            <div className="col-lg-6 col-md-6 forSmallDisplay">
              <div className="col-setup">
                <h3>Why Us</h3>
                <p>Low Maintenance</p>
                <p>Good Ethics in Business</p>
                <p>Quality Indian Craftsmanship in every machine we build</p>
              </div>
            </div>
          </div>
          <hr stylel={cssForHr} />
          <div
            className="row row-alignment align-items-center justify-content-center"
            data-aos="fade-up"
          >
            <div className="col-lg-6 col-md-6">
              <img src={aboutImg2} className="col-img" />
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="col-setup">
                <h3>Materials & Components</h3>
                <p>Mild Steel Plates</p>
                <p>Hydraulic Pumps</p>
                <p>Hydraulic Solonied Value</p>
                <p>Rubber Seds & 0-rings</p>
                <p>High Grade Materials</p>
              </div>
            </div>
          </div>
          <hr stylel={cssForHr} id="contact" />
          <div
            className="row row-alignment align-items-center justify-content-center"
            data-aos="fade-up"
          >
            <div className="col-lg-6 col-md-6 forLargeDisplay">
              <div className="col-setup">
                <h3>Contact Us</h3>
                <p>
                  <b>Business Type:</b> Exporter / Manufactures Suppliers
                </p>
                <p>
                  <b>Years Established:</b> 1998/99
                </p>
                <p>
                  <b>Contact Person:</b> Mr. Ramesh V. Malvi
                </p>
                <p>
                  <b>Mobile:</b> +91 97253 29739
                </p>
                <p>
                  <b>E-mail:</b> macbonindia@yahoo.com
                </p>
                <p>
                  <b>Address:</b> 26, Gujarat Vepari Maha Mandal, Odhav City,
                  Ahmedabad, Gujarat
                </p>
                <p>
                  <b>Pin Code:</b> 382415
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <img src={aboutImg4} className="col-img" />
            </div>
            <div className="col-lg-6 col-md-6 forSmallDisplay">
              <div className="col-setup">
                <h3>Contact Us</h3>
                <p>
                  <b>Business Type:</b> Exporter / Manufactures Suppliers
                </p>
                <p>
                  <b>Years Established:</b> 1998/99
                </p>
                <p>
                  <b>Contact Person:</b> Mr. Ramesh V. Malvi
                </p>
                <p>
                  <b>Mobile:</b> +91 97253 29739
                </p>
                <p>
                  <b>E-mail:</b> macbonindia@yahoo.com
                </p>
                <p>
                  <b>Address:</b> 26, Gujarat Vepari Maha Mandal, Odhav City,
                  Ahmedabad, Gujarat
                </p>
                <p>
                  <b>Pin Code:</b> 382415
                </p>
              </div>
            </div>
          </div>
          <hr stylel={cssForHr} />
          <div
            className="row row-alignment align-items-center justify-content-center"
            data-aos="fade-up"
          >
            <div className="col-lg-6 col-md-6">
              <img src={aboutImg3} className="col-img" />
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="col-setup">
                <h3>About Macbon Industries</h3>
                <p>
                  Professional Excellence and Strategic Marketing Methods We
                  have grow row-alignment tremendously and carved out a premier
                  position in the market places. Exporter and suppliers of
                  Hydraulic Deep Draw press, Hydraulic Baling press machine,
                  shearing machine and Trimming Machine and more. manufacture
                  using highly qualitive products of inn, seals and Directional
                  Control Valves and more with great overall quality and
                  performance and Excellence.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;

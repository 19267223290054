import "./App.css";
import Header from "./Components/Partials/Header";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect } from "react";

import Footer from "./Components/Partials/Footer";
import Home from "./Components/Home/Home";
import Gallery from "./Components/Gallery/Gallery";
import Products from "./Components/Products/Products";
import AOS from "aos";
import "aos/dist/aos.css";

import { library } from "@fortawesome/fontawesome-svg-core";

// import your icons
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

// const router = createBrowserRouter([
//   {
//     path: '/',
//     element: <MacbonLayout />
//   },
//   {
//     path: '/gallery',
//     element: <Gallery />
//   },
//   {
//     path: '/products',
//     element: <Products />
//   }
// ])

function App() {
  useEffect(() => {
    AOS.init({
      offset: 350,
      duration: 600,
    });

    var prevScrollpos = window.pageYOffset;
    var navbar = document.querySelector(".navbar");
    window.onscroll = function () {
      var currentScrollPos = window.pageYOffset;
      if (this.scrollY >= 150) {
        if (prevScrollpos > currentScrollPos) {
          navbar.style.opacity = "1";
          navbar.style.top = "0";
        } else {
          navbar.style.opacity = "0";
          navbar.style.top = "-80px";
        }
        prevScrollpos = currentScrollPos;
      }
    };
  }, []);
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/products" element={<Products />}>
            <Route path="/products#HDDP-model-1" element={<Products />} />
          </Route>
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;

library.add(fab, fas, far);

import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { Link, NavLink } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
import macbonLogo from "../img/macbon-logo.PNG";
import whatsAppLogo from "../img/whatsApp.png";

function Header() {
  // const navigate = useNavigate("")

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substr(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      // Scroll to top if no hash
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location]);

  return (
    <div>
      <div id="preloader">
        <span></span>
        <span></span>
      </div>
      <div className="socialIcons">
        <div className="icon">
          <img src={whatsAppLogo} />
          {/* target="_blank" */}
          <span>
            <a href="https://wa.me/919725329739" target="_blank">
              WhatsApp
            </a>
          </span>
        </div>
      </div>
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
          <Link to="/">
            <img src={macbonLogo} className="navbar-logo" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarText">
            <div className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link-color autoClose" to="/">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <a className="nav-link-color autoClose" href="/#about">
                  About Us
                </a>
              </li>
              <li className="nav-item">
                <Link className="nav-link-color autoClose" to="/gallery">
                  Gallery
                </Link>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className="nav-link-color dropdown-toggle"
                  data-bs-toggle="dropdown"
                  role="button"
                  aria-expanded="false"
                  to="/products"
                >
                  Products
                </Link>
                <ul className="dropdown-menu dropdown-menu-dark">
                  <li className="nav-item dropstart">
                    <a
                      className="dropdown-item dropdown-toggle"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Hydraulic Deep Draw Press
                    </a>
                    <ul className="dropdown-menu dropdown-menu-dark custom-drop-down">
                      <li>
                        <NavLink
                          to="/products#HDDP-model-1"
                          className="dropdown-item autoClose"
                        >
                          MI18AL
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/products#HDDP-model-2"
                          className="dropdown-item autoClose"
                        >
                          MI24AL
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/products#HDDP-model-3"
                          className="dropdown-item autoClose"
                        >
                          MI28AL
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/products#HDDP-model-4"
                          className="dropdown-item autoClose"
                        >
                          MI36AL
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/products#HDDP-model-5"
                          className="dropdown-item autoClose"
                        >
                          MI42AL
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/products#HDDP-model-6"
                          className="dropdown-item autoClose"
                        >
                          MI52AL
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/products#HDDP-model-7"
                          className="dropdown-item autoClose"
                        >
                          MI60AL
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/products#HDDP-model-8"
                          className="dropdown-item autoClose"
                        >
                          MI72AL
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li className="nav-item dropstart">
                    <a
                      className="dropdown-item dropdown-toggle"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Hydraulic Bailling Press
                    </a>
                    <ul className="dropdown-menu dropdown-menu-dark">
                      <li>
                        <NavLink
                          to="/products#HBP-model-1"
                          className="dropdown-item autoClose"
                        >
                          MIBP1515
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/products#HBP-model-2"
                          className="dropdown-item autoClose"
                        >
                          MIBP1824
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/products#HBP-model-3"
                          className="dropdown-item autoClose"
                        >
                          MIBP2020
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li className="nav-item dropstart">
                    <a
                      className="dropdown-item dropdown-toggle"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Hydraulic Deep Draw Press Single Action
                    </a>
                    <ul className="dropdown-menu dropdown-menu-dark">
                      <li>
                        <NavLink
                          to="/products#HDDSAP-model-1"
                          className="dropdown-item autoClose"
                        >
                          MISASS12
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/products#HDDSAP-model-2"
                          className="dropdown-item autoClose"
                        >
                          MISASS18
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <NavLink
                      className="dropdown-item autoClose"
                      to="/products#HPARM-model-1"
                    >
                      Hydraulic Punching And Riveting Machine
                    </NavLink>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <NavLink
                      className="dropdown-item autoClose"
                      to="/products#MTM-model-1"
                    >
                      Mechanical Trimming Machine
                    </NavLink>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <NavLink
                      className="dropdown-item autoClose"
                      to="/products#PPM-model-1"
                    >
                      Pneumatic Polishing Machine
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a className="nav-link-color autoClose" href="/#contact">
                  Contact
                </a>
              </li>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Header;

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Footer() {
  return (
    <div className="footer-box">
      <div className="container">
        <footer className="py-3">
          <ul className="nav justify-content-center border-bottom pb-3 mb-3">
            <li className="nav-item">
              <a href="#" className="nav-link px-2">
                <FontAwesomeIcon icon="fa-brands fa-youtube" />
              </a>
            </li>
            <li className="nav-item">
              <a href="#" className="nav-link px-2">
                <FontAwesomeIcon icon="fa-brands fa-facebook" />
              </a>
            </li>
            <li className="nav-item">
              <a href="#" className="nav-link px-2">
                <FontAwesomeIcon icon="fa-brands fa-instagram" />
              </a>
            </li>
          </ul>
          <p className="text-center">© Macbon Industries Since 1999</p>
          <p className="text-center text-muted" style={{ fontSize: "13px" }}>
            All Right Reserved !
          </p>
        </footer>
      </div>
    </div>
  );
}

export default Footer;

import React from 'react'
import { Link } from 'react-router-dom'
import './Gallery.css'
import galleryImg1 from '../img/construction-hanging-hook-concept_8829-2761.avif'

function Gallery() {
  return (
    <div class="underConstruction">
        <img src={galleryImg1} alt="" srcset="" />
        <Link to="/" title="Tap to go Home page"><button class="btn">Home</button></Link>
    </div>
  )
}

export default Gallery